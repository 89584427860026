import React, { useState, useRef, useEffect , useReducer} from "react";
import { Alert, Button, Modal } from "react-bootstrap";
import { QRCodeCanvas } from "qrcode.react";
import { Link } from "react-router-dom";
import axios from "axios";
import swal from "sweetalert";
//lg
import { useTranslation, initReactI18next } from 'react-i18next';
import i18n from 'i18next';

//** Import Image */

const BasicDatatable = () => {

 const { t } = useTranslation();
 const [loading, setLoading] = useState(false);
 const [securityCk, setUser] = useState([]);
 const [showModal, setShowModal] = useState(false);
  useEffect(()=>{
      const auth_token = localStorage.getItem('auth_token');
      const selectedCurrency = localStorage.getItem('selectedCurrency');
        const data = {
            key_s: 'eyJ0eXAiOiJKV1Qidfg%!#%$%LCdf#*&*JhbGciOiJSUzI1NiJ9',
            currency: selectedCurrency,
        }
      if (auth_token !== null) {
         axios.post('/security-home',data)
         .then(response=>{
           setUser(response.data)
           console.log(response.data)
           
           })
           .catch(error=>{
             console.log(error)
         })
      }

    },[])




    //For Email code sub
    const [depositInput, setDepositInput] = useState({
        email_code: '',
        error_list: [],
    });

    const handleInput = (e) => {
        e.persist();
        setDepositInput({...depositInput, [e.target.name]: e.target.value });
    }

    const depositSubmit = (e) => {
        e.preventDefault();
        const auth_token = localStorage.getItem('auth_token');
        const data = {
            email_code: depositInput.email_code,
            key_s: 'eyJ0eXAiOiJKV1Qidfg%!#%$%LCdf#*&*JhbGciOiJSUzI1NiJ9'
           
        }
      if (auth_token !== null) {
          axios.post(`/email-account-verify-sub`, data).then(res => {
                if(res.data.status === 'success')
                {

                   setShowModal(false);
                   window.location.reload();                  
                   swal("Success",res.data.message,"success");
                  setDepositInput({...depositInput,
                    email_code:'',
                    
                  });
                }
                else if(res.data.status === 'fail')
                {
                  swal("Warning",res.data.message,"warning");
                }
                else 
                {
                setDepositInput({...depositInput, error_list: res.data.validation_error});
                }
            });
          }

          }

    //For Email code sub


  const handleLinkClick = () => {
      const auth_token = localStorage.getItem('auth_token');
        const data = {
            key_s: 'eyJ0eXAiOiJKV1Qidfg%!#%$%LCdf#*&*JhbGciOiJSUzI1NiJ9',
            root_url: "https://account.xproyit.com/",
        }
      if (auth_token !== null && !loading) {
         setLoading(true);
         axios.post('/email-account-verify',data)
         .then(res=>{
          setLoading(false);
           if(res.data.status === 'success')
           {
             setShowModal(res.data.message);            
           }
           else if(res.data.status === 'fail')
           {
             swal("Warning",res.data.message,"warning");
           }
           else 
           {
             swal("Warning",res.data.message,"warning");
           }
            setLoading(false);
           })
           .catch(error=>{
             console.log(error)
         })
      }

 };


  let id;
  let identity_st;
  let mail_status;
  if (securityCk == "") {
    console.log(securityCk);
  }else{
    id=securityCk.data.id;
    identity_st=securityCk.data.identity_status;
    mail_status=securityCk.data.mail_status;

  }

  const closeModald = () => {
    setShowModal(false);
  };

  const closeModal = () => {
    setShowModal(false);
  };
  return (
    <div className="col-12">

            <div className="col-xl-12">
              <div className="row">

            { mail_status ===0 ? 
                <Alert  variant="danger" show="dfdsf" className='solid'>               
                    <strong> {t('pro_security_email_resend')} </strong>
                     <Link onClick={handleLinkClick} className='badge-sm light ms-1 badge badge-success'> {loading ? 'Sending...' : 'Send Mail For Account Varify'}</Link>  
                </Alert>
                : ""

              }


                <div className="col-xl-4 col-sm-4">
                <Link to="/change-password">
                  <div className="card booking">
                    <div className="card-body">
                    <svg xmlns="http://www.w3.org/2000/svg" width="20" height="20" viewBox="0 0 24 24" fill="none" stroke="#7ed321" stroke-width="3" stroke-linecap="round" stroke-linejoin="round"><path d="M22 11.08V12a10 10 0 1 1-5.93-9.14"></path><polyline points="22 4 12 14.01 9 11.01"></polyline></svg>
                       <div className="text-center">
                        <svg xmlns="http://www.w3.org/2000/svg" className="mb-3" width="70" height="70" viewBox="0 0 24 24" fill="none" stroke="#6a707a" stroke-width="2.5" stroke-linecap="round" stroke-linejoin="round"><rect x="3" y="11" width="18" height="11" rx="2" ry="2"></rect><path d="M7 11V7a5 5 0 0 1 9.9-1"></path></svg>
                        <h4 className="text-center">  {t('pro_security_ch_ps')}  </h4>
                        <span className="text-center text-white">  {t('pro_security_click_ch_ps')}  </span>
                      </div>
                    </div>
                  </div>
                  </Link>
                </div>

                <div className="col-xl-4 col-sm-4">
                  <div className="card booking">
                    <div className="card-body">
                    { mail_status ===0 ? 
                    <svg xmlns="http://www.w3.org/2000/svg" width="20" height="20" viewBox="0 0 24 24" fill="none" stroke="#ff0000" stroke-width="3" stroke-linecap="round" stroke-linejoin="round"><path d="M22 11.08V12a10 10 0 1 1-5.93-9.14"></path><polyline points="22 4 12 14.01 9 11.01"></polyline></svg>
                      :
                    <svg xmlns="http://www.w3.org/2000/svg" width="20" height="20" viewBox="0 0 24 24" fill="none" stroke="#7ed321" stroke-width="3" stroke-linecap="round" stroke-linejoin="round"><path d="M22 11.08V12a10 10 0 1 1-5.93-9.14"></path><polyline points="22 4 12 14.01 9 11.01"></polyline></svg>
                    }

                       <div className="text-center">
                        <svg xmlns="http://www.w3.org/2000/svg" className="mb-3" width="70" height="70" viewBox="0 0 24 24" fill="none" stroke="#6a707a" stroke-width="2.5" stroke-linecap="round" stroke-linejoin="round"><rect x="5" y="2" width="14" height="20" rx="2" ry="2"></rect><line x1="12" y1="18" x2="12.01" y2="18"></line></svg>
                        <h4 className="text-center"> {t('pro_security_to_gac_auth')} </h4>
                        <span className="text-center text-white"> {t('pro_security_to_gac_text')} </span>
                      </div>
                    </div>
                  </div>
                </div>


                <div className="col-xl-4 col-sm-4">
                {(identity_st===0) || identity_st===3 ? 
                <Link to="/identity-verification">
                  <div className="card booking">
                    <div className="card-body">
                    <svg xmlns="http://www.w3.org/2000/svg" width="20" height="20" viewBox="0 0 24 24" fill="none" stroke="#ff0000" stroke-width="3" stroke-linecap="round" stroke-linejoin="round"><path d="M22 11.08V12a10 10 0 1 1-5.93-9.14"></path><polyline points="22 4 12 14.01 9 11.01"></polyline></svg><span className="text-white">
                        <span style={{ color: '#db2a2a' }}> {
                            identity_st===0 ? " Unverified, Identity Verification Verify it"  : identity_st===1 ? " Verified"  : identity_st===2 ? " Submitted, (we will verify it within 4 hours) " : " Resubmit"
                         } </span>
                        </span> 
                       <div className="text-center">
                        <svg xmlns="http://www.w3.org/2000/svg" className="mb-3" width="70" height="70" viewBox="0 0 24 24" fill="none" stroke="#6a707a" stroke-width="2.5" stroke-linecap="round" stroke-linejoin="round"><path d="M5.52 19c.64-2.2 1.84-3 3.22-3h6.52c1.38 0 2.58.8 3.22 3"/><circle cx="12" cy="10" r="3"/><circle cx="12" cy="12" r="10"/></svg>
                        <h4 className="text-center"> {t('pro_security_nid')} </h4>
                        <span className="text-center text-white"> {t('pro_security_nid_tc')}  </span>
                      </div>
                    </div>
                  </div>
                  </Link>
                : 
                  <div className="card booking">
                    <div className="card-body">
                    <svg xmlns="http://www.w3.org/2000/svg" width="20" height="20" viewBox="0 0 24 24" fill="none" stroke="green" stroke-width="3" stroke-linecap="round" stroke-linejoin="round"><path d="M22 11.08V12a10 10 0 1 1-5.93-9.14"></path><polyline points="22 4 12 14.01 9 11.01"></polyline></svg><span className="text-white">
                         <span style={{ color: 'green' }}> {
                            identity_st===0 ? " Unverified"  : identity_st===1 ? " Verified"  : identity_st===2 ? " Submitted, (we will verify it within 4 hours) " : " Resubmit"
                         } </span>
                        </span> 
                       <div className="text-center">
                        <svg xmlns="http://www.w3.org/2000/svg" className="mb-3" width="70" height="70" viewBox="0 0 24 24" fill="none" stroke="#6a707a" stroke-width="2.5" stroke-linecap="round" stroke-linejoin="round"><path d="M5.52 19c.64-2.2 1.84-3 3.22-3h6.52c1.38 0 2.58.8 3.22 3"/><circle cx="12" cy="10" r="3"/><circle cx="12" cy="12" r="10"/></svg>
                        <h4 className="text-center"> {t('pro_security_nid')} </h4>
                        <span className="text-center text-white"> {t('pro_security_nid_tc')}  </span>
                      </div>
                    </div>
                  </div>
                 }
                </div>

              </div>
            </div>



          <Modal  show={showModal} onClose={closeModal}>
            <Modal.Header>
            <div class="modal-title h4">For Email verification</div>
              <Button variant="" className="btn-close" onClick={closeModald} type="button"></Button>
            </Modal.Header>
            <Modal.Body>
                <div className="col-md-12 order-md-1">
                  <div className="row">
                  <h4>{ showModal }</h4>
                   <form onSubmit={depositSubmit} className="needs-validation" noValidate="">
                      <div className="row">
                         <div className="col-md-12 mb-3">
                            <input
                               type="text"
                               className="form-control"
                               id="lastName"
                               placeholder="Email code"
                               required
                               name="email_code"
                               onChange={handleInput} 
                               value={depositInput.email_code}
                            />
                               <span className="text-danger">{depositInput.error_list.email_code}</span>
                         </div>

                      </div>

                        <button
                           className="btn btn-primary btn-lg btn-block"
                           type="submit"
                        >
                         Submit
                      </button>
                   </form>
                </div>
              </div>
            </Modal.Body>
            <Modal.Footer>
              
            <Button  onClick={closeModald}  variant="primary">Close</Button>
            </Modal.Footer>
          </Modal>


    </div>
  );
};

export default BasicDatatable;
