

import React, { Fragment, useState , useEffect } from "react";
//import Multistep from "react-multistep";
import { Stepper, Step } from 'react-form-stepper';
import axios from "axios";

import swal from "sweetalert";
import { Link } from "react-router-dom";
import PageTitle from "../../../layouts/PageTitle";

const Wizard = () => {
	const [goSteps, setGoSteps] = useState(0);


    const [registerInput, setRegister] = useState({
	    firstName:'',
	    lastName:'',
	    middleName:'',
	    date_birth:'',
	    address:'',
	    post_codes:'',
	    city:'',
	    your_image:'',
	    a_select:'',
	    nid_front_image:'',
	    nid_back_image:'',
	    passport_image:'',
	    driver_image:'',
        error_list: [],
    });


  const [productInput, setProduct] = useState({
    firstName:'',
    lastName:'',
    middleName:'',
    date_birth:'',
    address:'',
    post_codes:'',
    city:'',
    your_image:'',
    a_select:'',
    nid_front_image:'',
    nid_back_image:'',
    passport_image:'',
    driver_image:'',
  });

// YourImage live show
const [SetYouImg, AYouImage] = useState(null)
const [ggggggg, ApiYouImage] = useState([]);
const YourImage = (event) => {
 if (event.target.files && event.target.files[0]) {
   AYouImage(URL.createObjectURL(event.target.files[0]));
   ApiYouImage({your_image: event.target.files[0] });
 }
}

// SetNidFo live show
const [SetNidFo, ASetNidFo] = useState(null)
const [aaaaaaaa, ApiSetNidFo] = useState([]);
const NidYouImg = (event) => {
 if (event.target.files && event.target.files[0]) {
   ASetNidFo(URL.createObjectURL(event.target.files[0]));
   ApiSetNidFo({nid_front_image: event.target.files[0] });
 }
}

// NidBkImg live show
const [SetNidBkImg, ANidBkImg] = useState(null)
const [bbbbbbb, ApiNidBkImg] = useState([]);
const NidBkImg = (event) => {
 if (event.target.files && event.target.files[0]) {
   ANidBkImg(URL.createObjectURL(event.target.files[0]));
   ApiNidBkImg({nid_back_image: event.target.files[0] });
 }
}


// PassImg live show
const [SetPassImg, APassImg] = useState(null)
const [ccccccc, ApiPassImg] = useState([]);
const PassImg = (event) => {
 if (event.target.files && event.target.files[0]) {
   APassImg(URL.createObjectURL(event.target.files[0]));
   ApiPassImg({passport_image: event.target.files[0] });
 }
}

// DriverImg live show
const [SetDriverImg, ADriverImg] = useState(null)
const [ddddddd, ApiDriverImg] = useState([]);
const DriverImg = (event) => {
 if (event.target.files && event.target.files[0]) {
   ADriverImg(URL.createObjectURL(event.target.files[0]));
   ApiDriverImg({driver_image: event.target.files[0] });

 }
}



  const handleInput = (e) => {
   e.persist();
   setProduct({...productInput, [e.target.name]:e.target.value});

  }


const [picture, setPicture] = useState([]);

  const handleImage = (e) => {
   setPicture({product_image: e.target.files[0] });

  }


const [errorlist, setErrors] = useState([]);
const [loading, setLoading] = useState(false);
//form submit handler
  const submitProduct=(e) =>{
  	if(!loading){
 	  setLoading(true);
      e.preventDefault();
      const formData= new FormData();
      formData.append('key_s', "eyJ0eXAiOiJKV1Qidfg%!#%$%LCdf#*&*JhbGciOiJSUzI1NiJ9");
      formData.append('firstName', productInput.firstName);
      formData.append('lastName', productInput.lastName);
      formData.append('middleName', productInput.middleName);
      formData.append('date_birth', productInput.date_birth);
      formData.append('address', productInput.address);
      formData.append('post_codes', productInput.post_codes);
      formData.append('city', productInput.city);
      formData.append('your_image', ggggggg.your_image);
      formData.append('a_select', productInput.a_select);
      formData.append('nid_front_image', aaaaaaaa.nid_front_image);
      formData.append('nid_back_image', bbbbbbb.nid_back_image);
      formData.append('passport_image', ccccccc.passport_image);
      formData.append('driver_image', ddddddd.driver_image);
      axios.post('/kyc-verification', formData)
      .then((res)=>{
  	  setLoading(false);
            if(res.data.status === 'success')
            {
                localStorage.setItem('auth_token', res.data.accessToken);
                swal("Success",res.data.message,"success");
            }
           else if(res.data.status === 'fail')
            {
                swal("Warning",res.data.message,"warning");
            }
            else
            {
                setRegister({...registerInput, error_list: res.data.validation_error});
            }
     		setLoading(false);
      });
	}
  }

	return (
		<Fragment>

			<div className="row">
				<div className="col-xl-12 col-xxl-12">
					<div className="card">
						<div className="card-header">
							<h4 className="card-title">Personal Information</h4>
						</div>
						<div className="card-body">
						
						<form onSubmit={submitProduct} enctype= "multipart/form-data">
							<div className="form-wizard ">
								<Stepper className="nav-wizard" activeStep={goSteps} label={false}>
									<Step className="nav-link" onClick={() => setGoSteps(0)} />
									<Step className="nav-link" onClick={() => setGoSteps(1)} />
									<Step className="nav-link" onClick={() => setGoSteps(2)} />
								</Stepper>
							  {goSteps === 0 && (
								<>
						         <h4 className="text-center mb-4">Identity Information</h4>
						         <div className="row">
						            <div className="col-lg-6 mb-2">
						               <div className="form-group mb-3">
						                  <label className="text-label">First Name*</label>
						                  <input
						                     type="text"
						                     name="firstName"
						                     onChange={handleInput}
						                     value={productInput.firstName}
						                     className="form-control"
						                     placeholder="First Name"
						                     required
						                  />
                                  		<span className="text-primary">{registerInput.error_list.firstName}</span>
						               </div>
						            </div>
						            <div className="col-lg-6 mb-2">
						               <div className="form-group mb-3">
						                  <label className="text-label">Last Name*</label>
						                  <input
						                     type="text"
						                     name="lastName"
						                     onChange={handleInput}
						                     value={productInput.lastName}
						                     className="form-control"
						                     placeholder="Last Name"
						                     required
						                  />
                                  		<span className="text-primary">{registerInput.error_list.lastName}</span>
						               </div>
						            </div>
						            <div className="col-lg-6 mb-2">
						               <div className="form-group mb-3">
						                  <label className="text-label">Middle Names</label>
						                  <input
						                     type="text"
						                     name="middleName"
						                     onChange={handleInput}
						                     value={productInput.middleName}
						                     className="form-control"
						                     placeholder="Middle Names"
						                     required
						                  />
                                  		<span className="text-primary">{registerInput.error_list.middleName}</span>
						               </div>
						            </div>
						            <div className="col-lg-6 mb-2">
						               <div className="form-group mb-3">
						                  <label className="text-label">Date Of Birth*</label>
						                  <input
						                     type="date"
						                     name="date_birth"
						                     onChange={handleInput}
						                     value={productInput.date_birth}
						                     className="form-control"
						                     placeholder="Date Of Birth"
						                     required
						                  />
                                  		<span className="text-primary">{registerInput.error_list.date_birth}</span>
						               </div>
						            </div>
						         </div>
									<div className="text-end toolbar toolbar-bottom p-2">
										<button  className="btn btn-primary sw-btn-next" onClick={() => setGoSteps(1)}>Next</button>
									</div>	
								</>
							  )}
							  {goSteps === 1 && (
								<>
						         <h4 className="text-center mb-4">Additional Information</h4>
						         <div className="row">

						            <div className="col-lg-12 mb-3">
						               <div className="form-group mb-3">
						                  <label className="text-label">Your Address*</label>
						                  <input
						                     type="text"
						                     name="address"
						                     className="form-control"
						                     onChange={handleInput}
						                     value={productInput.address}
						                     required
						                  />
                                  		<span className="text-primary">{registerInput.error_list.address}</span>
						               </div>
						            </div>

						            <div className="col-lg-6 mb-2">
						               <div className="form-group mb-3">
						                  <label className="text-label">Post codes / Zip codes*</label>
						                  <input
						                     type="text"
						                     name="post_codes"
						                     className="form-control"
						                     onChange={handleInput}
						                     value={productInput.post_codes}
						                     placeholder="Post codes / Zip codes"
						                     required
						                  />
                                  		<span className="text-primary">{registerInput.error_list.post_codes}</span>
						               </div>
						            </div>

						            <div className="col-lg-6 mb-2">
						               <div className="form-group mb-3">
						                  <label className="text-label">City*</label>
						                  <input
						                     type="text"
						                     name="city"
						                     className="form-control"
						                     id="inputGroupPrepend2"
						                     onChange={handleInput}
						                     value={productInput.city}
						                     aria-describedby="inputGroupPrepend2"
						                     placeholder="City"
						                     required
						                  />
                                  		<span className="text-primary">{registerInput.error_list.city}</span>
						               </div>
						            </div>


						         </div>
									<div className="text-end toolbar toolbar-bottom p-2">
										<button  className="btn btn-secondary sw-btn-prev me-1" onClick={() => setGoSteps(0)}>Prev</button>
										<button className="btn btn-primary sw-btn-next ms-1" onClick={() => setGoSteps(2)}>Next</button>
									</div>	
								</>
							  )}
							  
							  {goSteps === 2 && (
								<>
						         <h4 className="text-center mb-4">Identity Information</h4>
						         <div className="row">


						            <div className="col-lg-12 mb-2">
						               <div className="form-group mb-3">
						                  <label className="text-label">Your Photo*</label>
						                    <input 
						                      className="form-control" 
						                      type="file" 
						                      id="formFile" 
						                      name="your_image"
						                      onChange={YourImage}
						                      src={SetYouImg}
						                      />
                                  			<span className="text-primary">{registerInput.error_list.your_image}</span>
						                      { SetYouImg ? (
  										    <img style={{ width: "70%" ,borderRadius: "20px"}} alt="Your Photo" src={SetYouImg}/>
						                      	) : "" }
						               </div>
						            </div>

						            <div className="col-lg-12 mb-3">
	                                    <label>Government-issued document</label>
	                                    <select name="a_select" 
	                                       onChange={handleInput} 
	                                       required
	                                       value={productInput.a_select}
	                                       className="form-control">
	                                           <option value="" >Select document type</option>
	                                           <option value="nid" >ID Card</option>
	                                           <option value="passport" >Passport</option>
	                                           <option value="driver" >Driver's License</option>
	                                         
	                                    </select>
                                  		<span className="text-primary">{registerInput.error_list.a_select}</span>
						            </div>



						            <span  style={{ display: productInput.a_select == "nid" ? "inherit" : "none"  }}>

						            <div className="col-lg-6 mb-2 p-1">
						               <div className="form-group mb-3">
						                  <label className="text-label">Select NID front side*</label>
						                    <input 
						                      className="form-control" 
						                      type="file" 
						                      id="formFile" 
						                      name="nid_front_image"
						                      onChange={NidYouImg}
						                      src={SetNidFo}
						                      />
                                  			<span className="text-primary">{registerInput.error_list.nid_front_image}</span>
						                      { SetNidFo ? (
  										    <img style={{ width: "100%" ,borderRadius: "20px"}} alt="NID front side" src={SetNidFo}/>
						                      	) : "" }
						               </div>
						            </div>

						            <div className="col-lg-6 mb-2  p-1">
						               <div className="form-group mb-3">
						                  <label className="text-label">Select NID back side*</label>
						                    <input 
						                      className="form-control" 
						                      type="file" 
						                      id="formFile" 
						                      name="nid_back_image"
						                      onChange={NidBkImg}
						                      src={SetNidBkImg}
						                      />
                                  			<span className="text-primary">{registerInput.error_list.nid_back_image}</span>
						                      { SetNidBkImg ? (
  										    <img style={{ width: "100%",borderRadius: "20px" }} alt="NID back SetNidBkImg" src={SetNidBkImg}/>
						                      	) : "" }
						               </div>
						            </div>
						        </span>

						        <span  style={{ display: productInput.a_select == "passport" ? "inherit" : "none"  }}>
						            <div className="col-lg-12 mb-2 p-1">
						               <div className="form-group mb-3">
						                  <label className="text-label">Select Your Passport*</label>
						                    <input 
						                      className="form-control" 
						                      type="file" 
						                      id="formFile" 
						                      name="passport_image"
						                      onChange={PassImg}
						                      src={SetPassImg}
						                      />
                                  			<span className="text-primary">{registerInput.error_list.passport_image}</span>
						                      { SetPassImg ? (
  										    <img style={{ width: "100%",borderRadius: "20px" }} alt="Passport SetPassImg" src={SetPassImg}/>
						                      	) : "" }
						               </div>
						            </div>
						        </span>

                                  
						            <span  style={{ display: productInput.a_select == "driver" ? "inherit" : "none"  }}>

						            <div className="col-lg-12 mb-2 p-1">
						               <div className="form-group mb-3">
						                  <label className="text-label">Select Your Driver*</label>
						                    <input 
						                      className="form-control" 
						                      type="file" 
						                      id="formFile" 
						                      name="driver_image"
						                      onChange={DriverImg}
						                      src={SetDriverImg}
						                      />
                                  			<span className="text-primary">{registerInput.error_list.driver_image}</span>
						                      { SetDriverImg ? (
  										    <img style={{ width: "100%",borderRadius: "20px" }} alt="driver SetYouImg" src={SetDriverImg}/>
						                      	) : "" }
						               </div>
						            </div>
						        </span>
						         </div>

                              		<p className="text-primary">{registerInput.error_list.firstName}</p>
                              		<p className="text-primary">{registerInput.error_list.lastName}</p>
                              		<p className="text-primary">{registerInput.error_list.middleName}</p>
                              		<p className="text-primary">{registerInput.error_list.date_birth}</p>
                              		<p className="text-primary">{registerInput.error_list.address}</p>
                              		<p className="text-primary">{registerInput.error_list.city}</p>
                              		<p className="text-primary">{registerInput.error_list.your_image}</p>
                              		<p className="text-primary">{registerInput.error_list.a_select}</p>
                              		<p className="text-primary">{registerInput.error_list.nid_front_image}</p>
                              		<p className="text-primary">{registerInput.error_list.nid_back_image}</p>
                              		<p className="text-primary">{registerInput.error_list.passport_image}</p>
                              		<p className="text-primary">{registerInput.error_list.driver_image}</p>					         
									<div className="text-end toolbar toolbar-bottom p-2">
										<button  className="btn btn-secondary sw-btn-prev me-1" onClick={() => setGoSteps(1)}>Prev</button>
										<button type="submit" className="btn btn-primary sw-btn-next ms-1">{loading ? 'Submitting...' : 'Submit'}</button>
									</div>	
								</>
							  )}
							  
							  
							</div>

               			 </form>

						</div>
					</div>
				</div>
			</div>
		</Fragment>
	);
};

export default Wizard;