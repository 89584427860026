import React, { Fragment,useState,useEffect } from "react";
import { Form } from "react-bootstrap";
import PageTitle from "../../../layouts/PageTitle";
import { useParams, Link } from "react-router-dom";
import axios from "axios";
import swal from "sweetalert";
import loading_img from "../../../../images/profile/loading.gif";

const Checkout = () => {
   const { id } = useParams();
   const [loading, setLoading] = useState(true);
      const [deposit, setDeposit] = useState([]);

    useEffect(()=>{
        
      const data = {
        key_s: 'eyJ0eXAiOiJKV1Qidfg%!#%$%LCdf#*&*JhbGciOiJSUzI1NiJ9',
        pg_id:id,
        }
        axios.post('/view-page',data)
        .then(response=>{
           setDeposit(response.data.data)
           setLoading(false);
         })
     .catch(error=>{
            
           console.log(error)
       })

           
   },[id]);


    if(loading)
    {
        return <div className="d-flex justify-content-center" ><img src={loading_img} alt="" className="me-3 rounded" width={75}/></div> 
    }
   else
    {
   return (
      <Fragment>
         <div className="row">
            <div className="col-xl-12">
            <div className="card">
              <div className="card-body">

                <div className="post-details">
                  <h3 className="mb-2 text-black">
                    {deposit.titel}
                  </h3>
                  <img src="" alt="" className="img-fluid mb-3 w-100 rounded" />
                  <p>
                    {deposit.Discription}
                  </p>
                </div>

              </div>
            </div>
            </div>
         </div>
      </Fragment>
   )};
};

export default Checkout;
