import React,{useState} from 'react';
import {Link} from 'react-router-dom';
import Calendar from "react-calendar";
import "react-calendar/dist/Calendar.css";


function Btn1(){
	return(
		<span className="date bg-secondary mb-3">3</span>
	)
}
function Btn2(){
	return(
		<span className="date2 bg-primary mb-3">16, 17, 18</span>
	)
}
function Btn3(){
	return(
		<span className="date bg-warning mb-3">3</span>
	)
}



const RecentBooking = () =>{
	const [value, onChange] = useState(new Date());
	const [refresh, setRefresh] = useState(false);
	return(
		<>


		</>
	)
}
export default RecentBooking;