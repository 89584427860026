import React, { Fragment,useState,useEffect } from "react";
import { Form } from "react-bootstrap";
import PageTitle from "../../../layouts/PageTitle";
import { useParams } from "react-router-dom";
import axios from "axios";
import swal from "sweetalert";

const Checkout = () => {


const [depositInput, setDepositInput] = useState({
  amount: 20000,
  password: '',
  error_list: [],
});

const handleInput = (e) => {
  e.persist();
  setDepositInput({...depositInput, [e.target.name]: e.target.value });
}

 const [selectedProductId, setSelectedProductId] = useState('');
 const [selectedTypeOfPlot, setTypeOfPlot] = useState('');
 const [selectedsetInstallment, setInstallment] = useState('');
 const [selectedPaymentTyp, setSelectedPaymentTtyp] = useState('');
 const [projectTypes, setProjectTypes] = useState([]);
 const [InstallmentAmountGt, InstallmentAmount] = useState([]);
 const [ResPaymentTypes, resPaymentType] = useState([]);
 const selectedCurrency = localStorage.getItem('selectedCurrency');
 const auth_token = localStorage.getItem('auth_token');
 const [loadingdd, setLoadingd] = useState(false); // Add loading state
 const [loadingdda, setLoadingda] = useState(false); // Add loading state
  
    useEffect(() => {
        if (selectedProductId !== '') {
              const data = {
                  key_s: 'eyJ0eXAiOiJKV1Qidfg%!#%$%LCdf#*&*JhbGciOiJSUzI1NiJ9',
                  currency: selectedCurrency,
                  product_id: selectedProductId,
              }
            if (auth_token !== null) {
               axios.post('/apps-project-type',data)
               .then(response=>{
                setLoadingd(true);
                 setProjectTypes(response.data.data)
                  InstallmentAmount([])
                  resPaymentType([])
                  setSelectedPaymentTtyp([])
                 })
                 .catch(error=>{
                   console.log(error)
               })
            }
        }
    }, [selectedProductId]);

  
  // Call fetchDatainstallment() when selectedTypeOfPlot changes
  useEffect(() => {
      if (selectedTypeOfPlot !== '' && selectedPaymentTyp === 'installment') {
          fetchDatainstallment();
      }
  }, [selectedTypeOfPlot, selectedPaymentTyp]);





    useEffect(() => {
        if (selectedPaymentTyp !== '' && selectedPaymentTyp == 'installment') {
              const data = {
                  key_s: 'eyJ0eXAiOiJKV1Qidfg%!#%$%LCdf#*&*JhbGciOiJSUzI1NiJ9',
                  currency: selectedCurrency,
                  payment_type: selectedPaymentTyp,
                  selectedProductId: selectedProductId,
              }
            if (auth_token !== null) {
               axios.post('/apps-payment-type',data)
               .then(response=>{
                  setLoadingda(true);
                  resPaymentType(response.data.data)
                 })
                 .catch(error=>{
                   console.log(error)
               })
            }
        }else if(selectedPaymentTyp !== '' && selectedPaymentTyp  !== ''){
          resPaymentType([])
            const data = {
              key_s: 'eyJ0eXAiOiJKV1Qidfg%!#%$%LCdf#*&*JhbGciOiJSUzI1NiJ9',
              currency: selectedCurrency,
              payment_type: selectedPaymentTyp,
              selectedProductId: selectedProductId,
            }
           axios.post('/packege-one-select',data)
           .then(response=>{
            setLoadingd(true);
             InstallmentAmount(response.data.data)
             })
             .catch(error=>{
               console.log(error)
           })          
        }else{
          resPaymentType([])
        }
    }, [selectedPaymentTyp]);


  const fetchDatainstallment = () => {
    if (selectedsetInstallment !== '' && selectedProductId !== '' && selectedPaymentTyp !== '' && selectedPaymentTyp == 'installment' && selectedTypeOfPlot !== '') {
          const data = {
              key_s: 'eyJ0eXAiOiJKV1Qidfg%!#%$%LCdf#*&*JhbGciOiJSUzI1NiJ9',
              currency: selectedCurrency,
              selectedProductId: selectedProductId,
              selectedPaymentTyp: selectedPaymentTyp,
              selectedTypeOfPlot: selectedTypeOfPlot,
              selectedsetInstallment: selectedsetInstallment,
          }
        if (auth_token !== null) {
           axios.post('/apps-installment-amount',data)
           .then(response=>{
            setLoadingd(true);
             InstallmentAmount(response.data.data)
             })
             .catch(error=>{
               console.log(error)
           })
        }
    }
  };
  useEffect(() => {
      fetchDatainstallment();
  }, [selectedsetInstallment]);


  let installment_total;
  let installment_amount;
  let booking_fee;
  if (InstallmentAmountGt !== "") {
    installment_total=InstallmentAmountGt.installment_total;
    installment_amount=InstallmentAmountGt.installment_amount;
    booking_fee=InstallmentAmountGt.booking_fee;
  }else{
    installment_total= "0";
    installment_amount= "0";
    booking_fee= "0";
  }



let projectTypesdd = null;
if (loadingdd) {
    projectTypesdd = (
      <>
          {projectTypes.length > 0 && (
              <h4 className="mb-3">Type of Plot</h4>
          )}
          {projectTypes.map((ele, index) => (
              <div key={index} className="col-xl-4 col-xxl-4 col-12">
                  <div style={{ background: "#ddd", color: '#707070' }} className="card booking">
                      <div style={{ textAlign: 'center' }}>
                          <label data-kt-button="true">
                              <div style={{ marginTop: "20px" }} className="booking-status d-flex align-items-center">
                                  <div>
                                      <h5 style={{ maxWidth: '200px', color: '#707070' }} className="mb-0 font-w600">{ele.packege_name}</h5>
                                  </div>
                              </div>
                              <div>
                                  <div className="grid-product__content">
                                      <p style={{ marginBottom: '0px' }} className="title">Select</p>
                                      <input
                                          className="form-check-input border border-gray-500"
                                          type="radio"
                                          name="type_of_plot"
                                          value={ele.id}
                                          checked={selectedTypeOfPlot === ele.id}
                                          onChange={() => setTypeOfPlot(ele.id)}
                                      />

                                      <br />
                                      <br />
                                  </div>
                              </div>
                          </label>
                      </div>
                  </div>
              </div>
          ))}
      </>
    );
}



let paymentTypesdd = null;
if (loadingdda) {
    paymentTypesdd = (
      <>
          {ResPaymentTypes.length > 0 && (
              <h4 className="mb-3">Type of Installment</h4>
          )}
          {ResPaymentTypes.map((ele, index) => (
              <div key={index} className="col-xl-4 col-xxl-4 col-12">
                  <div style={{ background: "#ddd", color: '#707070' }} className="card booking">
                      <div style={{ textAlign: 'center' }}>
                          <label data-kt-button="true">
                              <div style={{ marginTop: "20px" }} className="booking-status d-flex align-items-center">
                                  <div>
                                      <h5 style={{ maxWidth: '200px', color: '#707070' }} className="mb-0 font-w600">{ele.installment_name}</h5>
                                  </div>
                              </div>
                              <div>
                                  <div className="grid-product__content">
                                      <p style={{ marginBottom: '0px' }} className="title">Select</p>
                                      <input
                                          className="form-check-input border border-gray-500"
                                          type="radio"
                                          name="type_installment"
                                          value={ele.id}
                                          onChange={() => setInstallment(ele.id)}
                                      />
                                      <br />
                                      <br />
                                  </div>
                              </div>
                          </label>
                      </div>
                  </div>
              </div>
          ))}
      </>
    );
}




 const [income, setIncome] = useState([]);
    useEffect(()=>{
        const data = {
            key_s: 'eyJ0eXAiOiJKV1Qidfg%!#%$%LCdf#*&*JhbGciOiJSUzI1NiJ9',
            currency: selectedCurrency,
        }
      if (auth_token !== null) {
         axios.post('/apps-project',data)
         .then(response=>{
           setIncome(response.data.data)
           
           })
           .catch(error=>{
             console.log(error)
         })
      }

    },[])






const [loading, setLoading] = useState(false);
const depositSubmit = (e) => {
  e.preventDefault();
  const auth_token = localStorage.getItem('auth_token');
  const data = {
      amount: depositInput.amount,
      password: depositInput.password,
      currency: selectedCurrency,
      selectedProductId: selectedProductId,
      selectedPaymentTyp: selectedPaymentTyp,
      selectedTypeOfPlot: selectedTypeOfPlot,
      selectedsetInstallment: selectedsetInstallment,      
      key_s: 'eyJ0eXAiOiJKV1Qidfg%!#%$%LCdf#*&*JhbGciOiJSUzI1NiJ9'
  }
 if (auth_token !== null && !loading) {
    setLoading(true);
    axios.post(`/land-booking`, data).then(res => {
       if(res.data.status === 'success')
       {
         swal("Success",res.data.message,"success");
         setDepositInput({...depositInput,
           amount:'20000',
           password:'',
           
         });
       }
       else if(res.data.status === 'fail')
       {
         swal("Warning",res.data.message,"warning");
       }
       else 
       {
       setDepositInput({...depositInput, error_list: res.data.validation_error});
       }
       setLoading(false);
   });
 }
}

   return (
      <Fragment>
         <div className="row">
            <div className="col-xl-12">
               <div className="card">
                  <div className="card-body">
                           <form onSubmit={depositSubmit}>
                     <div className="row">


                        <div className="col-xl-12">
                          <div className="row mydivpadding d-flex justify-content-center">

                           <h4 className="mb-3">Select Project  </h4>
                            {income.map((incomerow, index )=>(
                                <div className="col-xl-4 col-xxl-4 col-12">
                                  <div style={{ background: "#ddd" , color: '#707070'}} className="card booking">
                                    <div  style={{ textAlign: 'center' }}>
                                    <label data-kt-button="true">
                                      <span style={{ maxWidth: '200px' , color: '#707070', padding: '10px', background: 'gold', borderRadius: '50px', fontSize: '20px' }} className="mb-0 font-w600">{index + 1}</span>

                                        <div style={{marginTop: "20px"}} className="booking-status d-flex align-items-center">
                                          <div>
                                            <h4 style={{ maxWidth: '200px' , color: '#707070' }} className="mb-0 font-w600">{incomerow.project_name}</h4>
                                          </div>
                                        </div>
                                        <div>
                                          <div className="grid-product__content">
                                            <p style={{ marginBottom: '0px' }} className="title">Select</p>
                                            <input
                                                    className="form-check-input border border-gray-500"
                                                    type="radio"
                                                    name="product_id"
                                                    value={incomerow.id}
                                                    onChange={() => setSelectedProductId(incomerow.id)} // Update selectedProductId state
                                                />
                                            <br />
                                            <br />
                                          </div>                       
                                        </div>
                                     </label>   
                                    </div>
                                  </div>
                                </div>

                              ))}

                          </div>
                        </div>




                      <div className="row" style={{margin: "0px auto"}}>
                         {projectTypesdd}
                      </div>

                      {loadingdd && (
                        <div className="row" style={{margin: "0px auto"}}>
                             <h4 className="mb-3">Payment Type </h4>

                            <div className="col-xl-4 col-xxl-4 col-4 p-1">
                                <div style={{ background: "#ddd", color: '#707070' }} className="card booking">
                                    <div style={{ textAlign: 'center' }}>
                                        <label data-kt-button="true">
                                            <div style={{ marginTop: "20px" }} className="booking-status d-flex align-items-center">
                                                <div>
                                                    <h5 style={{ maxWidth: '200px', color: '#707070' }} className="mb-0 font-w600">One Time</h5>
                                                </div>
                                            </div>
                                            <div>
                                                <div className="grid-product__content">
                                                    <p style={{ marginBottom: '0px' }} className="title"></p>
                                                    <input
                                                        className="form-check-input border border-gray-500"
                                                        type="radio"
                                                        name="payment_type"
                                                        value="one_time"
                                                        checked={selectedPaymentTyp === "one_time"}
                                                        onChange={() => setSelectedPaymentTtyp("one_time")}
                                                    />
                                                    <br />
                                                    <br />
                                                </div>
                                            </div>
                                        </label>
                                    </div>
                                </div>
                            </div>


                            <div className="col-xl-4 col-xxl-4 col-4 p-1">
                                <div style={{ background: "#ddd", color: '#707070' }} className="card booking">
                                    <div style={{ textAlign: 'center' }}>
                                        <label data-kt-button="true">
                                            <div style={{ marginTop: "20px" }} className="booking-status d-flex align-items-center">
                                                <div>
                                                    <h5 style={{ maxWidth: '200px', color: '#707070' }} className="mb-0 font-w600">Installment</h5>
                                                </div>
                                            </div>
                                            <div>
                                                <div className="grid-product__content">
                                                    <p style={{ marginBottom: '0px' }} className="title"></p>
                                                    <input
                                                        className="form-check-input border border-gray-500"
                                                        type="radio"
                                                        name="payment_type"
                                                        value="installment"
                                                        checked={selectedPaymentTyp === "installment"}
                                                        onChange={() => setSelectedPaymentTtyp("installment")}
                                                    />
                                                    <br />
                                                    <br />
                                                </div>
                                            </div>
                                        </label>
                                    </div>
                                </div>
                            </div>


                            <div className="col-xl-4 col-xxl-4 col-4 p-1">
                                <div style={{ background: "#ddd", color: '#707070' }} className="card booking">
                                    <div style={{ textAlign: 'center' }}>
                                        <label data-kt-button="true">
                                            <div style={{ marginTop: "20px" }} className="booking-status d-flex align-items-center">
                                                <div>
                                                    <h5 style={{ maxWidth: '200px', color: '#707070' }} className="mb-0 font-w600">Down Pay</h5>
                                                </div>
                                            </div>
                                            <div>
                                                <div className="grid-product__content">
                                                    <p style={{ marginBottom: '0px' }} className="title"></p>
                                                    <input
                                                        className="form-check-input border border-gray-500"
                                                        type="radio"
                                                        name="payment_type"
                                                        value="down_pay"
                                                        checked={selectedPaymentTyp === "down_pay"}
                                                        onChange={() => setSelectedPaymentTtyp("down_pay")}
                                                    />
                                                    <br />
                                                    <br />
                                                </div>
                                            </div>
                                        </label>
                                    </div>
                                </div>
                            </div>

                        </div>
                        )}


                      <div className="row" style={{margin: "0px auto"}}>
                         {paymentTypesdd}
                      </div>




                        <div className="col-md-4 order-md-2 mb-4">
                           <h4 className="d-flex justify-content-between align-items-center mb-3">
                              <span className="text-muted">Confirmation Check</span>
                              <span className="badge badge-primary badge-pill">
                                $
                              </span>
                           </h4>
                           <ul className="list-group mb-3">

                              <li className="list-group-item d-flex justify-content-between lh-condensed">
                                 <div>
                                    <h6 className="my-0">Booking Money</h6>
                                    <small className="text-muted">
                                      Total Booking Money 
                                    </small>
                                 </div>
                                 <span className="text-muted">{booking_fee} TK</span>
                              </li>

                              <li className="list-group-item d-flex justify-content-between lh-condensed">
                                 <div>
                                    <h6 className="my-0">Total Amount</h6>
                                    <small className="text-muted">
                                       Total Amount Fee
                                    </small>
                                 </div>
                                 <span className="text-muted">{installment_total} TK</span>
                              </li>
                           
                              <li className="list-group-item d-flex justify-content-between lh-condensed">
                                 <div>
                                    <h6 className="my-0">Installment Fee</h6>
                                    <small className="text-muted">
                                      Total Bokking Installment Fee 
                                    </small>
                                 </div>
                                 <span className="text-muted">{installment_amount} TK</span>
                              </li>
                           </ul>
                        </div>


                        <div className="col-md-8 order-md-1">
                           <h4 className="mb-3">Others Option </h4>
                              <div className="row">
                                 <div className="col-md-6 mb-3">
                                    <label htmlFor="lastName">Amount</label>
                                    <input
                                       type="text"
                                       className="form-control"
                                       id="lastName"required
                                       placeholder="Amount"
                                       name="amount"
                                       pattern="[0-9]+(\.[0-9]+)?"
                                       onChange={handleInput} 
                                       value={depositInput.amount}
                                       readOnly
                                    />
                                       <span className="text-danger">{depositInput.error_list.amount}</span>
                                 </div>

                                 <div className="col-md-6 mb-3">
                                    <label htmlFor="firstName">
                                       Account Password
                                    </label>
                                    <input
                                       type="password"
                                       className="form-control"
                                       id="firstName"
                                       placeholder="Password"
                                       required
                                       name="password"
                                       onChange={handleInput} 
                                       value={depositInput.password}
                                    />
                                       <span className="text-danger">{depositInput.error_list.password}</span>
                                    
                                 </div>
                              </div>

                              <hr className="mb-4" />
                              <div className="form-check custom-checkbox mb-2">
                                 <input
                                    type="checkbox"
                                    className="form-check-input"
                                    id="same-address"
                                    required

                                 />
                                 <label
                                    className="form-check-label"
                                    htmlFor="same-address"
                                 >
                                    Accept treams & condition
                                 </label>
                              </div>
                              <hr className="mb-4" />


                            {InstallmentAmountGt == "" ? (
                              <button
                                className="btn btn-warning btn-lg btn-block"
                                type="submit"
                                disabled
                              >
                                Booking Continue
                              </button>
                            ) : (
                              <button
                                 className="btn btn-warning btn-lg btn-block"
                                 type="submit"
                                 disabled={loading}
                              >
                                {loading ? 'Submitting...' : 'Booking Continue'}
                              </button>
                            )}


                        </div>


                     </div>
                     </form>
                  </div>
               </div>
            </div>
         </div>
      </Fragment>
   );
};

export default Checkout;
